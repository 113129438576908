// src/Resources/UserEdit.js
import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput, Create, ImageInput, ImageField
} from 'react-admin';

const PortfolioCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
          <SelectInput source="type"  choices={[
            { id: '0', name: 'Wszystkie' },
            { id: '1', name: 'E-commerce' },
            { id: '2', name: 'Custom' },
              { id: '3', name: 'Wizytówkowe' },
        ]} />
          <TextInput source="href" />

          <TextInput source="title" />
          <TextInput source="description" />
          <ImageInput source="image" label="Image" accept="image/*">
              <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
    </Create>
);

export default PortfolioCreate;