import React from 'react';
import { List, Datagrid, TextField, EditButton, ImageField, SelectField, Pagination } from 'react-admin';

const PostPagination = (props) => (
    <Pagination rowsPerPageOptions={[10]} {...props} />
);

const PortfolioList = (props) => (
    <List {...props} pagination={<PostPagination />}>
        <Datagrid>
            <TextField source="id" />
            <SelectField source="type" choices={[
                { id: 0, name: 'Wszystkie' },
                { id: 1, name: 'E-commerce' },
                { id: 2, name: 'Custom' },
                { id: 3, name: 'Wizytówkowe' },
            ]} />
            <TextField source="href" />
            <ImageField source="image" />
            <TextField source="description" />
            <EditButton />
        </Datagrid>
    </List>
);

export default PortfolioList;
