import React from 'react';
import {
    Edit, FileField, FileInput, ImageField, ImageInput, SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';

const PortfolioEdit = () => (
    <Edit>
        <SimpleForm>
          <SelectInput source="type" choices={[
            { id: '0', name: 'Wszystkie' },
            { id: '1', name: 'E-commerce' },
            { id: '2', name: 'Custom' },
              { id: '3', name: 'Wizytówkowe' },
        ]} />
          <TextInput source="href" />
          <TextInput source="title" />
          <TextInput source="description" />
          <ImageField source="src" title="title" />
            <ImageInput source="image" label="Change Portfolio Picture">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

export default PortfolioEdit;
