// Example of a custom data provider
import { fetchUtils } from 'react-admin';

const apiUrl = 'https://backend.grupalucrum.pl/src';
// const apiUrl = 'https://127.0.0.1:8000/api';
const httpClient = fetchUtils.fetchJson

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const url = `${apiUrl}/${resource}?page=${page}&perPage=${perPage}`;

    return httpClient(url)
      .then(response => ({
        data: response.json,
        total: parseInt(response.headers.get('x-total-count')),
      }));
  },
  getOne: (resource, params) => {
    const url = `${apiUrl}/${resource}/?id=${params.id}`;
    return httpClient(url)
      .then(({ json }) => {
        if (!json.hasOwnProperty('id')) {
          throw new Error('Response data does not contain an id');
        }
        return { data: json };
      })
      .catch(error => {
        console.error('getOne error', error);
        throw error;
      });
  },
  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(query)}`;
    return httpClient(url).then(({ json }) => ({
      data: json,
    }));
  },

  delete: async (resource, params) => {
        const url = `${apiUrl}/${resource}/?id=${params.id}`;
        const { json } = await httpClient(url, {
            method: 'DELETE',
        });
        return { data: json };
    },
  deleteMany: (resource, idsParam) => {
        console.log('deleteMany called with:', { resource, idsParam });

        let ids = idsParam;
        if (typeof ids === 'object' && ids.ids) {
            ids = ids.ids;
        }

        if (!Array.isArray(ids)) {
            console.error('Invalid argument: ids must be an array. Received:', ids);
            // Return a valid response format with an empty data array
            return Promise.resolve({ data: [] });
        }

        const query = ids.map(id => `id[]=${encodeURIComponent(id)}`).join('&');
        const url = `${apiUrl}/${resource}?${query}`;

        return fetch(url, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            })
        })
        .then(response => {
            if (!response.ok) {
                return Promise.reject(new Error('Failed to delete resources.'));
            }
            return response.json();
        })
        .then(json => {
            // Ensure the response format is correct
            if (!Array.isArray(json)) {
                console.error('Invalid response format:', json);
                // Return an empty data array to satisfy the expected format
                return { data: [] };
            }
            return { data: json };
        })
        .catch(error => {
            console.error('Bulk delete error:', error);
            // Return a valid response format with an empty data array
            return { data: [] };
        });
    },


create: (resource, params) => {
    const url = `${apiUrl}/${resource}`;

    // Create a FormData instance
    const formData = new FormData();
    formData.append('type', params.data.type);
    formData.append('href', params.data.href);
    formData.append('title', params.data.title);
    formData.append('description', params.data.description);

    // Append image file if it exists
    if (params.data.image && params.data.image.rawFile) {
      formData.append('image', params.data.image.rawFile);
    }

    const options = {
      method: 'POST',
      body: formData,
    };

    return httpClient(url, options)
      .then(({ json }) => {
        // if (!json.hasOwnProperty('id')) {
        //   throw new Error('Response data does not contain an id');
        // }
        return { data: { ...params.data, id: json.id } }; // Ensure ID is included
      })
      .catch(error => {
        console.error('Create error', error);
        throw error;
      });
  },
  update: (resource, params) => {
  console.log('Updating user with data:', params.data);

  const url = `${apiUrl}/${resource}/?id=${params.id}`;
  const formData = new FormData();

  // Append fields to formData
  formData.append('type', params.data.type);
  formData.append('href', params.data.href);
  formData.append('title', params.data.title);
  formData.append('description', params.data.description);

  if (params.data.image && params.data.image.rawFile) {
    console.log('Appending image:', params.data.image.rawFile);
    formData.append('image', params.data.image.rawFile);
  }

  // Print FormData contents for debugging
  for (let [key, value] of formData.entries()) {
    console.log(key, value);
  }

  const options = {
    method: 'PUT',
    body: formData,
  };

  return httpClient(url, options)
    .then(({ json }) => {
      console.log('Update successful:', json);
      return { data: json };
    })
    .catch(error => {
      console.error('Update error:', error);
      throw error;
    });
},

  // Other CRUD methods...
};

export default dataProvider;
